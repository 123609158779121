import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from './Button';
import { LoadingButton } from './LoadingButton';
import { IconButton } from './IconButton';

// Avoid prop overriding when the child component does not need such prop
const childOverrideValue = (type, propName, propValue) => {
  if (type === IconButton) {
    return null;
  }
  return { [propName]: propValue };
};

const buttonTypeSpacing = {
  regular: 'sui-gap-4 sm:sui-gap-4',
  icon: 'sui-gap-x-2 sm:sui-gap-x-1 sui-gap-1'
};

/**
 *
 * The `ButtonGroup` component can be used to group related buttons.
 *
 * Related components: [Button](#button), [IconButton](#iconbutton), [LoadingButton](#loadingbutton)
 *
 * Usage:
 *
 * ```jsx
 * import { ButtonGroup } from '@one-thd/sui-atomic-components';
 * ```
 */
const ButtonGroup = React.forwardRef((props, ref) => {

  const {
    children,
    orientation = 'horizontal',
    disabled = false,
    fullWidth = false,
    variant = 'secondary',
    type = 'regular',
    responsive = true,
    ...other
  } = props;

  const gapClasses = buttonTypeSpacing[type];
  const isHorizontal = orientation === 'horizontal';
  const isVertical = orientation === 'vertical';
  const classes = classNames('sui-flex', gapClasses, {
    'sui-flex-col sui-w-full': isVertical,
    'sui-flex-col sm:sui-flex-row': isHorizontal && responsive,
    'sui-flex-row': isHorizontal && !responsive,
    'sui-w-full': isHorizontal && fullWidth,
    'sui-w-full sm:sui-w-auto': isHorizontal && !fullWidth && responsive
  });

  return (
    <div
      className={classes}
      ref={ref}
      {...other}
    >
      {children.map((child, index) => {
        return React.cloneElement(child, {
          disabled: child.props.disabled || disabled,
          ...childOverrideValue(child.type, 'variant', child.props.variant || variant),
          ...childOverrideValue(child.type, 'fullWidth', isVertical ? true : (child.props.fullWidth || fullWidth)),
          key: index
        });
      })}
    </div>
  );
});

ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([Button, LoadingButton, IconButton])
  })).isRequired,
  /**
   * The component orientation (layout flow direction).
   * @default 'horizontal'
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * The variant to use.
   * @default 'secondary'
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'text']),
  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the buttons will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * Indicates whether the group orientation is responsive or not.
   * When a 'horizontal' oriented group is responsive, it changes to 'vertical' orientation on small screens.
   * @default true
   */
  responsive: PropTypes.bool,
  /**
   * The spacing type to use between the child buttons.
   * For `Button` and `LoadingButton` groups use 'regular'.
   * For `IconButton` groups use 'icon'.
   * @default 'regular'
   */
  type: PropTypes.oneOf(['regular', 'icon'])
};

ButtonGroup.defaultProps = {};

export { ButtonGroup };
